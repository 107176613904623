.CreateSalesOrderPage {
  display: flex;
  flex-direction: column;
}

.CreateSalesOrderPage .loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.CreateSalesOrderPage .ribbon {
  min-height: 1px;
  flex-shrink: 0;
}

.CreateSalesOrderPage .body {
  flex: 1;
  overflow: auto;
}

.CreateSalesOrderPage .title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.CreateSalesOrderPage .formCont {
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 200px;
}

.CreateSalesOrderPage .formCont .formRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

.CreateSalesOrderPage .itemsCont {
  margin-bottom: 20px;
}

.CreateSalesOrderPage .itemsCont .itemRow {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 5px;
  margin-bottom: 10px;
}

.CreateSalesOrderPage .itemsCont .itemRowContent {
  display: flex;
  justify-content: space-between;
}

.CreateSalesOrderPage .itemsCont .itemRowContent .fields {
  flex: 1;
  min-width: 0;
}

.CreateSalesOrderPage .itemRow.error {
  border-width: 3px;
  border-color:red;
}

.CreateSalesOrderPage .errorBox {
  font-weight: bold;
  text-align: center;
  color: red;
  margin-bottom: 10px;
}

.CreateSalesOrderPage .header {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.ComplianceNotes .section {
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.ComplianceNotes .section .h {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
  .CreateSalesOrderPage .formCont .formRow {
    flex-direction: column;
  }

  .CreateSalesOrderPage .formCont .formRow > * {
    flex: 1;
    align-items: stretch;
    width: 100%;
  }
}
