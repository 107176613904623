.WorkOverview .WorkOverviewCategory {
  margin-bottom: 10px;
}

.WorkOverview .WorkOverviewCategory .WorkOverviewCategoryHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.WorkOverview .WorkOverviewCategory .WorkOverviewCategoryHeader:hover {
  background-color: #ddd;
}

.WorkOverview .WorkOverviewCategory .WorkOverviewCategoryHeader .name {
  max-width: 200px;
  flex: 1;
  padding: 5px;
}

.WorkOverview .WorkOverviewCategory .WorkOverviewCategoryHeader .visual {
  flex: 1;
}
