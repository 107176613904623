.App {
  text-align: center;
}

.appCont {
  display: flex;
  justify-content: stretch;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
}

.appCont .bottomCont {
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: stretch;
  display: flex;
}

.appCont .pageCont {
  flex: 1;
  overflow: auto;
  position: relative;
  min-width: 100vw;
}

.navBody {
  background-color: #aa1e2e;
  transition: width 0.5s;
  overflow: hidden;
  padding: 10px;
  color: white;
  text-overflow: clip;
  white-space: nowrap;
  user-select: none;
}

.navBody.hide {
  padding: 0;
}

.navBody ul:first-of-type {
  padding: 0;
}

.navToggle {
  background-color: #aa1e2e;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #1c252c;
}

.navToggle .title {
  cursor: pointer;
  padding: 5px;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.navToggle svg {
  width: 30px;
}

.navToggle .auth {
  padding: 5px;
}

.navBody a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 3px;
}

.navBody a.active {
  background-color: rgb(99, 98, 98);
  color: white;
}

.navBody a:hover {
  background-color: rgb(150, 148, 148);
  color: white;
}

.Page {
  padding: 5px;
}

.Page.fullHeight {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.Page.homepage{
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.form {
  padding: 10px;
}

.header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.formRow {
  margin: 15px 0;
}

.formRow.error {
  color: red;
  font-weight: bold;
}

.MuiDataGrid-root p {
  margin: auto;
}

.gridCell.overFlow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gridCell.allWidth {
  width: 100%;
  padding: 0 5px;
}

.gridCell.allWidth > div {
  width: 100%;
}

.gridCell.allHeight {
  height: 100%;
  display: flex;
  align-items: center;
}

.gridCell.error {
  background-color: red;
}

.EstimateEditor {
  width: 100vw;
  max-width: 500px;
}

.EstimateEditor .previousCont {
  font-size: 12px;
}

.EstimateEditor .previousCont > div {
  margin-bottom: 10px;
  border-bottom: solid 1px #b8babb;
}

.AdminLink a {
  color: white;
  text-decoration: none;
}

.AdminLink a.active {
  color: rgb(0, 0, 0);
}

.ribbon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 2px 0;
  margin-bottom: 5px;
  gap: 5px;
}
.ribbon > * {
  flex-shrink: 0;
}
.ribbon::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.ribbon {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ribbon button,
.ribbon input {
  font-size: 12px !important;
}

.ribbon .MuiToggleButton-root {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ribbon .MuiInputBase-input {
  padding-top: 5px;
  padding-bottom: 5px;
}

.dateRangeSelector {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (max-width: 1000px) {
  .ribbon .xlonly {
    display: none;
  }
}
